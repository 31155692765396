import React from 'react'
import loadable from "@loadable/component"
import LayoutTwo from "../components/layoutTwo"
import Seo from '../components/seo'
import { graphql } from 'gatsby'
import sitelogoimage from "../images/logo.svg";
import { PageLinks } from '../common/site/page-static-links'
import NewHomesImg_small from "../images/new_homes_img_small_1.png"

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"))
const NewHomesBanner = loadable(() => import("../components/NewHomesBanner/NewHomesBanner"))
const NewHomesFeaturesList = loadable(() => import("../components/NewHomesFeaturesList/NewHomesFeaturesList"))
const NewHomesDetailDesc = loadable(() => import("../components/NewHomesDetailDesc/NewHomesDetailDesc"))
const SimilarProperties = loadable(() => import("../components/SimilarProperties/SimilarProperties"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const NewhomesDetails = ({ data }) => {

    const property = data?.strapiNewDevelopments
    const companyphone = data?.site?.siteMetadata?.mailVars?.company_phone
    const officeDetailGlobal = data?.strapiOfficeDetail

    let processedImages = property?.ggfx_results

    let propertyDetailImg = [];
    for (let i = 0; i < property?.images?.strapi_json_value.length; i++) {
        if (property?.images?.strapi_json_value[i].url) {
            propertyDetailImg.push(property?.images?.strapi_json_value[i].url);
        }
    }

    // floorplan lightbox
    let propertyFloorplanImg = [];
    for (let i = 0; i < property?.floorplan?.strapi_json_value.length; i++) {
        if (property?.floorplan?.strapi_json_value[i].srcUrl) {
            propertyFloorplanImg.push(property?.floorplan?.strapi_json_value[i].srcUrl);
        }
    }

    // brochure lightbox
    let propertyBrochureImg = [];
    for (let i = 0; i < property?.brochure?.strapi_json_value.length; i++) {
        if (property?.brochure?.strapi_json_value[i]) {
            propertyBrochureImg.push(property?.brochure?.strapi_json_value[i]);
        }
    }

    // epc lightbox
    let propertyEpcImg = [];
    for (let i = 0; i < property?.epc?.strapi_json_value.length; i++) {
        if (property?.epc?.strapi_json_value[i].srcUrl) {
            propertyEpcImg.push(property?.epc?.strapi_json_value[i].srcUrl);
        }
    }

    let prices;
    if (property?.price && property?.max_price) {
        prices = `£${property?.price?.toLocaleString()} - £${property?.max_price?.toLocaleString()}`
    } else if (property?.price) {
        prices = `£${property?.price?.toLocaleString()}`
    } else {
        prices = `£${property?.max_price?.toLocaleString()}`
    }
    return (
        <LayoutTwo>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule
                parentlabel={"New Homes"}
                parentlink={PageLinks.newhomes_landing}
                subparentlabel={"New Homes for sale"}
                subparentlink={PageLinks.new_developments}
                title={property.display_address}
            />

            <NewHomesBanner
                bannerLogo={NewHomesImg_small}
                images={property?.images}
                processedImages={processedImages}
                strapi_id={property?.strapi_id}
                crm_id={property?.crm_id}
                propsImg={propertyDetailImg}
                propertyFloorplanImg={propertyFloorplanImg}
                display_name={property?.address?.address1}
                display_address={property?.display_address}
                prices={prices}
            />

            <NewHomesFeaturesList
                propsImg={propertyDetailImg}
                propertyFloorplanImg={propertyFloorplanImg}
                propertyBrochureImg={propertyBrochureImg}
            />

            <NewHomesDetailDesc
                property={property}
                description={property?.description?.data?.description || property?.additional_description?.data?.additional_description}
                crm_negotiator_email={property?.crm_negotiator_email}
                companyphone={companyphone}
                propertyEpcImg={propertyEpcImg}
            />

            <SimilarProperties
                prop_id={property?.strapi_id}
                showDividerLine
                tag="new-homes"
                title="Similar new homes for sale"
            />

            <SellLetHome {...officeDetailGlobal.footer_help_module} />
        </LayoutTwo>
    )
}


export const query = graphql`
  query ($crm_id: String) {
    strapiNewDevelopments(crm_id: { eq: $crm_id }) {
      ...NewHomesFragment
    }
    site {
        siteMetadata {
            mailVars {
                company_phone
            }
        }
    }
    strapiOfficeDetail {
        footer_help_module {
          ...PlainContentFragment
        }
    }
  }
`
export const Head = (props) => {
    //seo title, h1 and desc prepare, this can send to utilis function later
    let desc_meta_ptype = props.data.strapiNewDevelopments?.building?.strapi_json_value?.length > 0 ? props.data.strapiNewDevelopments?.building?.strapi_json_value.join(" and ") : "new homes"

    let cap_ptype = desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1);

    let desc_meta_seachtype = `for sale`
    let desc_meta_beds = props.data.strapiNewDevelopments?.bedroom
    let desc_meta_address = props.data.strapiNewDevelopments?.display_address
    let desc_meta_price = `£` + new Intl.NumberFormat('en-UK').format(props.data.strapiNewDevelopments?.price)

    let pagetitle = `${cap_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}`
    let pagemetadesc = `Find the details of ${desc_meta_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}. with Madison Fox. Request a viewing to get assistance in buying this new build ${desc_meta_ptype}`

    let pageUrl = process.env.GATSBY_SITE_URL + (props?.location?.pathname).replace(/\/?$/, '/')
    let myimgtransforms = ''
    if (props.data.strapiNewDevelopments?.imagetransforms?.images_Transforms) {
        myimgtransforms = JSON.parse(props.data.strapiNewDevelopments?.imagetransforms?.images_Transforms);
    }
    let pageImg = sitelogoimage;
    if (myimgtransforms && Object.keys(myimgtransforms).length > 0) {
        let mymetaimg = Object.values(myimgtransforms);
        let pageImg_filt = []
        for (const myimgtransform in myimgtransforms) {
            if (typeof myimgtransforms[myimgtransform]['webp'] !== "undefined") {
                pageImg_filt = Object.values(myimgtransforms[myimgtransform]['webp']);
                break;
            }
        }
        if (pageImg_filt.length > 0)
            pageImg = pageImg_filt[0]
    }


    var ldJson = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": props.data.strapiNewDevelopments?.slug.replace(/-/g, " "),
        "image": props.data.strapiNewDevelopments?.images?.strapi_json_value[0]?.url,
        "description": pagemetadesc,
        "brand": {
            "@type": "Organization",
            "name": process.env.GATSBY_SITE_NAME,
            "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`
        },
        "offers": {
            "@type": "Offer",
            "url": pageUrl,
            "priceCurrency": "GBP",
            "price": props.data.strapiNewDevelopments?.price,
            "availability": "https://schema.org/InStock"
        }

    };
    return (
        <Seo title={pagetitle} description={pagemetadesc}>
            <meta name="image" content={pageImg} />
            <meta name="og:image" content={pageImg} />
            <meta name="og:image:width" content="400" />
            <meta name="og:image:height" content="300" />
            <meta name="twitter:image" content={pageImg} />
            <meta name="og:url" content={pageUrl} />
            <meta name="twitter:url" content={pageUrl} />
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
            />
        </Seo>
    )
}

export default NewhomesDetails